<template>
    <div class="setting_wpr">
        <div class="w-100">
            <label for="launchpad-edit-contact" class="switch_option capsule_btn">
                <h5>Edit Contact Details</h5>
                <input type="checkbox" :true-value="1" :false-value="0" id="launchpad-edit-contact" v-model="editContact" hidden>
                <div><span></span></div>
            </label>
            <AddEditContact v-model="editContact" :contact="contact" />
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Client ID <a class="option" @click="createMemberID = !createMemberID">Create Custom ID</a></label>
                    <div class="field_wpr has_suffix">
                        <Field autocomplete="off" v-model="selectedContact.member_id" name="member_id" type="text" placeholder="Enter Client ID" />
                        <span class="suffix pointer" @click="resendMemberId(contact.id)">Resend</span>
                    </div>
                </div>
            </div>
            <div v-if="createMemberID" class="form_grp">
                <Form v-slot="{ errors }" ref="lauchpad-member-id-form">
                    <div class="group_item">
                        <label class="input_label">New Client ID</label>
                        <div class="field_wpr has_suffix" :class="{ 'has-error': errors.member_id }">
                            <Field autocomplete="off" v-model="memberForm.contact_id" name="contact_id" type="hidden" rules="required" />
                            <Field autocomplete="off" v-model="memberForm.member_id" name="custom_member_id" type="text" placeholder="Enter Client ID" rules="required" label="Client ID" />
                            <span class="suffix pointer" @click="handleMemberIDCreate()"><i class="fa fa-spinner fa-spin" v-if="contactLoader"></i> {{ contactLoader ? 'Creating' : 'Create' }}</span>
                        </div>
                        <ErrorMessage name="custom_member_id" class="text-danger" />
                    </div>
                </Form>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Portal Password <a class="option" @click="handlePasswordGenerate()">Generate Password</a></label>
                    <div class="field_wpr has_suffix">
                        <Field autocomplete="off" v-model="selectedContact.credential" name="member_id" type="text" />
                        <span class="suffix pointer" @click="handleSavePortalPassword(contact.id)" :disabled="updatePortalPasswordLoader">
                            <i class="fa fa-spin fa-spinner" v-if="updatePortalPasswordLoader"></i>&nbsp;{{ updatePortalPasswordLoader ? 'Updating' : 'Update' }}
                        </span>
                    </div>
                    <span role="alert" class="text-danger" v-if="portalPasswordError">{{ portalPasswordError }}</span>
                </div>
            </div>
            <div class="text_info" v-if="selectedContact.valid_email == 0">
                The email <span>{{ selectedContact.email }}</span> has been reported as invalid. Please click edit and update to a valid email or verify it is correct by clicking the check mark.
                <a href="javascript:void(0);" class="mr-2" @click="markAsValidEmail(selectedContact.id)"><i class="fas fa-check"></i></a>
                <a href="javascript:void(0);" class="mr-2" @click="editContact = true"><i class="fas fa-edit"></i></a>
            </div>
             <label for="status" class="switch_option capsule_btn">
                <h5>Portal Status</h5>
                <input type="checkbox" @change="handleEnableDisable('portal', $event, 'is_active')" v-model="selectedContact.is_active" :true-value="1" :false-value="0" id="status" hidden>
                <div><span></span></div>
            </label>
            <label for="email" class="switch_option capsule_btn">
                <h5>Ok to send Email</h5>
                <input type="checkbox" @change="handleEnableDisable('email', $event, 'email_enabled')" v-model="selectedContact.email_enabled" :true-value="1" :false-value="0" id="email" hidden>
                <div><span></span></div>
            </label>
            <label for="sms" class="switch_option capsule_btn">
                <h5>Ok to send SMS</h5>
                <input type="checkbox" @change="handleEnableDisable('sms', $event, 'sms_enabled')" v-model="selectedContact.sms_enabled" :true-value="1" :false-value="0" id="sms" hidden>
                <div><span></span></div>
            </label>
            <label for="broadcast" class="switch_option capsule_btn">
                <h5>Ok to send Broadcast</h5>
                <input type="checkbox" @change="handleEnableDisable('broadcast', $event, 'broadcast_enabled')" v-model="selectedContact.broadcast_enabled" :true-value="1" :false-value="0" id="broadcast" hidden>
                <div><span></span></div>
            </label>
            <label for="birthday" class="switch_option capsule_btn">
                <h5>Celebrate Birthday</h5>
                <input type="checkbox" @change="handleEnableDisable('birthday', $event, 'celebrate_birthday')" v-model="selectedContact.celebrate_birthday" :true-value="1" :false-value="0" id="birthday" hidden>
                <div><span></span></div>
            </label>
            <div v-if="editBirthday" class="details_wpr">
                <div class="cardItem_details">
                    <button class="close_btn" @click="editBirthday = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Edit Birthday</h2>
                    <Form @submit="handleUpdateBirthday" v-slot="{ errors }">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Birthday</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.birthdate }">
                                    <Field autocomplete="off" name="birthdate" v-model="birthdayForm.birthdate" rules="required">
                                        <datepicker v-model="birthdayForm.birthdate" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                    </Field>
                                </div>
                            </div>
                            <ErrorMessage name="birthdate" class="text-danger" />
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="birthdayLoader" type="button" class="btn cancel_btn" @click="editBirthday = false; selectedContact.celebrate_birthday = 0;">Cancel</button>
                            <button :disabled="birthdayLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="birthdayLoader"></i> {{ birthdayLoader ? 'Saving' : 'Save' }}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const AddEditContact = defineAsyncComponent(() => import('@/pages/contact/components/AddEditContact'))

    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    export default {
        name: 'Launchpad Setting',

        data () {
            return {
                editContact: false,
                createMemberID: false,
                selectedContact: {
                    email_enabled: 0,
                    sms_enabled: 0,
                    broadcast_enabled: 0,
                    celebrate_birthday: 0,
                    is_active:0,
                },
                memberForm: {
                    contact_id: '',
                    member_id: '',
                },
                editBirthday: false,
                birthdayLoader: false,
                birthdayForm: {
                    contact_id: 0,
                    birthdate: '',
                },
                portalPasswordError: '',
            };
        },

        props: {
            contact: Object,
        },

        components: {
            AddEditContact,
            Field,
            Form,
            ErrorMessage,
        },

        computed: mapState({
            contactLoader: state => state.contactModule.contactSaveLoader,
            updatePortalPasswordLoader: state => state.contactModule.updatePortalPasswordLoader,
        }),

        mounted () {
            const vm = this;

            setTimeout(function () {
                vm.selectedContact = JSON.parse(JSON.stringify(vm.contact));

                vm.memberForm = {
                    contact_id: vm.contact.contact_id ? vm.contact.contact_id : vm.contact.id,
                    member_id: vm.contact.member_id,
                };
            }, 10);
        },

        methods: {
            ...mapActions({
                resendMemberId: 'contactModule/resendMemberId',
                markAsValidEmail: 'contactModule/markAsValidEmail',
                updateMemberID: 'contactModule/updateMemberID',
                enableDisableDeliveryStatus: 'contactModule/enableDisableDeliveryStatus',
                updateBirthday: 'contactModule/updateBirthday',
                savePortalPassword: 'contactModule/savePortalPassword',
                portalAccountActiveSuspend: 'contactModule/portalAccountActiveSuspend',
            }),

            handleMemberIDCreate () {
                const vm = this;
                const form = vm.$refs['lauchpad-member-id-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.memberForm.setFieldError = form.setFieldError;

                        vm.updateMemberID(vm.memberForm).then((result) => {
                            if (result) {
                                vm.createMemberID = false;
                                vm.selectedContact.member_id = vm.memberForm.member_id;
                            }
                        });
                    }
                });
            },

            handleEnableDisable (type, event, index) {
                const vm = this;
                const status = event.target._modelValue;

                if (!vm.selectedContact.birthdate && type == 'birthday' && status == 1) {
                    const options = Helper.swalConfirmOptions('Oops!', 'This contact does not have a birthday on file. Please update and save before activating birthdays.', 'Yes', false)

                    Swal.fire(options).then((result) => {
                        if (result.isConfirmed) {
                            vm.editBirthday = true;
                        } else {
                            vm.selectedContact[index] = 0;
                        }
                    });
                } else {
                    let options = Helper.swalConfirmOptions('Are you sure?', `Do you want to ${ status ? 'enable' : 'disable' } ${ type } sending?`);

                    if (type == 'portal') {
                        options = Helper.swalConfirmOptions('Are you sure?', `Do you want to ${ status ? 'activate' : 'suspend' } this client portal?`);
                    }

                    options.preConfirm = function () {
                        if (type == 'portal') {
                            return vm.portalAccountActiveSuspend({ contacts: vm.contact.id, type: status == 1 ? 'active': 'suspended', status });
                        } else {
                            return vm.enableDisableDeliveryStatus({ contact_id: vm.contact.id, type, status });
                        }
                    };

                    Swal.fire(options).then((result) => {
                        if (!result.isConfirmed) {
                            vm.selectedContact[index] = status ? 0 : 1;
                        }
                    });
                }
            },

            handleUpdateBirthday (params, { setFieldError }) {
                const vm = this;

                vm.birthdayForm.setFieldError = setFieldError;
                vm.birthdayForm.contact_id = vm.contact.id;
                vm.birthdayLoader = true;

                vm.updateBirthday(vm.birthdayForm).then((result) => {
                    if (result) {
                        vm.editBirthday = false;
                        vm.selectedContact.celebrate_birthday = 1;

                        vm.selectedContact.birthdate = vm.birthdayForm.birthdate;
                        vm.birthdayForm = { contact_id: 0, birthdate: '' };

                        vm.enableDisableDeliveryStatus({ contact_id: vm.contact.id, type: 'birthday', status: 'enable' });
                    }

                    vm.birthdayLoader = false;
                });
            },

            handlePasswordGenerate () {
                const vm = this;
                let password = '';
                const length = 8;
                const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

                for (var i = 0, n = charset.length; i < length; ++i) {
                    password += charset.charAt(Math.floor(Math.random() * n));
                }

                vm.selectedContact.credential = password;
            },

            handleSavePortalPassword (contact) {
                const vm = this;

                if (vm.selectedContact.credential && vm.selectedContact.credential.length >= 8) {
                    vm.portalPasswordError = '';

                    const options = Helper.swalConfirmOptions('Are you sure?', `Do you want to reset portal password?`);

                          options.preConfirm = function () {
                              return vm.savePortalPassword({ contact, password: vm.selectedContact.credential });
                          };

                    Swal.fire(options);
                } else if (vm.selectedContact.credential && vm.selectedContact.credential.length < 8) {
                    vm.portalPasswordError = 'The password must be at least 8 characters';
                } else {
                    vm.portalPasswordError = 'The password field is required';
                }
            },
        },
    }
</script>

<style scoped>
    .field_wpr.has_suffix .suffix {
        width: 100px;
    }
</style>